 .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 }
 
 .inputDiv{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
 }

 .input{
    width: 40%;
    margin: auto; 
 }

 .filterBtn{
    margin: auto;
    margin-top: 25px;
    width: 25%;
    height: 35px;
    color: #0B77C2;
    background-color: #ffffff;
    border: none;
    font-size: medium;
    font-weight: bold;
    box-shadow: 0 0 10px #0000001A;
    cursor: pointer;
    border-radius:5px;
 }

 .num {
   padding-left: 25px;
   padding-right: 25px;
   color: #0B77C2;
   margin-bottom: 5px;
 }

 .line {
   border: 1px solid #0B77C2; 
   margin: 0 20px;
   margin-top: 5px;
 }