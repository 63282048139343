.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    
}
.container-sub {
    display: flex;
    flex-direction: column;
    flex-direction: column;
    min-height: 100vh;
}

.num {
  padding-left: 25px;
  padding-right: 25px;
  color: #0B77C2;
  margin-top: 20px;
}

.line {
  border: 1px solid #0B77C2; 
  margin: 0 20px;
  margin-top: 5px;
}

.rowStyle {
  margin-top: 2% !important;
  justify-content: center;
}

.divStyle {
  box-shadow: 0 0 15px #0000001A;
  margin-bottom: 10px;
  min-height: 335px;
}

.img{
  height: 70px;
  width: 60px;
  border-radius:5px;
}

.name {
  color: #eff2f5 !important;
  margin-top: 10px;
  font-weight: bold !important;
}

.shopId {
  color: #eff2f5 !important;
  margin: 10px 10px;
  margin-left: -0.3px;
  font-size: small;
  font-weight: bold;
}

.popUpTitle {
  color: #007DBC I !important;
  font-weight: bold;
}


.details {
  font-family: Arial, Helvetica, sans-serif;
  font-size: large;
  font-weight: 500;
}

.promotionDiv {
  position: relative;
  border: 1px solid #0B77C2;
  overflow: hidden;
  padding: 10px 10px;
  box-shadow: 0 0 2px #0B77C2;
  width: 100%;
  min-height: 120px;
}

.promotionTxt {
  margin-left: 5px;
  margin-right: 5px;
  max-width: 270px;
}

.load {
  color: #0B77C2;
  margin-left: 30px;
  margin-right: 30px;
  font-weight: bold;
}

.buttonIcon {
  width:27px;
  height: 27px;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-left: 1px;
  margin-right: 1px;
}

.buttonText {
  padding-top: 10px;
  font-size: large;
  font-weight: bolder !important;
  text-decoration: none;
  color: #0B77C2;
  text-align: center;
}

.cardHeader {
  background-color: #0B77C2 !important;
}

.cardFooter {
  background-color: #f9fbfc !important;
}

.locationButton {
  margin: 5px 5px !important;
}

.icon{
  height: 40px;
  width: 30px;
}

.disabilityDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}