.navBarContainer {
    background-color: #0B77C2;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    height: 50px;
    pointer-events: none;
    margin-top: -5px;
    margin-left: 30px;
    margin-right: 10px;
}

.sideDiv {
    display: flex;
    flex-direction: column;
    align-items: end;
}

.navbar, .offcanvas {
    padding: 0 !important;
}

.navbar-brand {
    padding: 0 !important;
}

.container-fluid {
    padding: 0 !important;
}

.navBarSmall {
    display: flex;
    flex-direction: column;
}

.custom-navbar-toggler {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 70px;
    margin-top: 30px;
    color: #ffffff !important;
}

.offCanvaDiv {
    display: flex;
    flex-direction: column;
}

.offCanvaTitle {
    color: #ffffff !important;
}

.offcanvas-header {
    display: flex;
    justify-content: space-between;
}

  
.headerName {
    color: #0B77C2;
    border-bottom: 2px solid #0B77C2;
    align-items: center;
    text-align: center;
    padding-bottom: 5px;
    margin-bottom: 20px;
}
  
.translationList {
    list-style: none;
    gap: 1rem;
    color: #ffffffff;
    font-size: small;
    text-align: center;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row; 
    gap: 10px;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 10px;
    margin-top: -15px;
}

.translationList li {
    text-align: left;
  }
  
.translation {
    cursor: pointer;
    color: white;
    text-decoration: none;    
}

.translation:hover {
    font-weight: bolder;
}

.leftButton {
    display: flex;
    color: #ffffffff;
    font-size: 20px;
    width: 100%;
    height: 100%;
    cursor: pointer;
    padding: 10px;
}

a {
    text-decoration: none !important;
}

button {
    margin-bottom: 20px !important;
    margin-right: 10px !important;
    margin-left: 10px !important;
}
  


